import { graphql, useStaticQuery } from "gatsby";
import {
  extract,
  ProductStore,
  ShowStore,
  RideStore,
  RestaurantStore,
  JetshopStore,
  PlaceholderImageHelper,
  TextBlockStore,
} from "@parksandresorts/core";

const StaticDataFactory = (props) => {
  const data = useStaticQuery(query);

  JetshopStore.populate(data?.allJetshopProduct?.nodes ?? []);
  ProductStore.populateStore(extract(data, "allContentfulProductBlock.edges"));
  ShowStore.populateStore(extract(data, "allContentfulShowBlock.edges"));
  RideStore.populateStore(extract(data, "allContentfulRideBlock.edges"));
  RestaurantStore.populateStore(extract(data, "allContentfulRestaurantBlock.edges"));
  TextBlockStore.populateStore(extract(data, "allContentfulTextBlock.nodes"));

  PlaceholderImageHelper.setPlaceholderImages({ eventRow: "/icon-192x192.png" });

  return <>{props.children}</>;
};

const query = graphql`
  {
    allJetshopProduct {
      nodes {
        glkId
        productId
        price
        previousPrice
        attributes {
          attributeId
          articleNumber
          attributeName
          isBuyable
          stockStatus
          price
          priceString
          discountPrice
          discountPriceString
        }
        stockLevel
        isBuyable
        stockStatus
        productName
        minimumQuantity
      }
    }
    allContentfulProductBlock(filter: { productName: { ne: null } }) {
      edges {
        node {
          ...ProductBlockStore
        }
      }
    }
    allContentfulShowBlock {
      edges {
        node {
          ...ShowBlock
        }
      }
    }
    allContentfulRideBlock(filter: { title: { ne: null } }) {
      edges {
        node {
          ...RideBlockStore
        }
      }
    }
    allContentfulRestaurantBlock(filter: { title: { ne: null } }) {
      edges {
        node {
          node_locale
          ...RestaurantBlock
        }
      }
    }
    allContentfulTextBlock(filter: { richText: { raw: { ne: null } } }) {
      nodes {
        id
        contentful_id
        node_locale
        internalTitle
        componentTheme
        richText {
          raw
          references {
            __typename
            ...Link
            ...Asset
          }
        }
      }
    }
  }
`;

export default StaticDataFactory;
